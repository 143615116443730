import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ForgetPassword = React.lazy(() => import('./views/pages/forget-passwords/ForgetPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/forget-passwords/ResetPassword'))
const  Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const PrivacyPolicy = React.lazy(() => import('./views/pages/policies/PrivacyPolicy'))
const TermsAndConditions = React.lazy(() => import('./views/pages/policies/TermsAndConditions'))

const  App = () => {
    return (
      <BrowserRouter>
        <Suspense>
          <Routes>
          <Route exact path="/test" element={<Login />} />
          <Route exact path="/" name="Login Page" element={<Login />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/error/404" name="404 Page" element={<Page404 />} />
            <Route
              exact
              path="/forget-password"
              name="Forget Password Page"
              element={<ForgetPassword />}
            />
            <Route
              exact
              path="/reset-password/:token"
              name="Reset Password Page"
              element={<ResetPassword />}
            />
            <Route exact path="/privacy-policy?" name="Privacy Policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-and-conditions?" name="Terms and Conditions" element={<TermsAndConditions />} />
            <Route path="*" name="Login Layout" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
}

export default App
